.table-container {
  .table {
    .footer-container {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .pager-container {
        display: flex;
        max-width: 700px;
      }

      .datatable-pager {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        ::ng-deep {
          .pager {
            .pages {
              padding: 6px 12px;
              text-align: center;
              border-radius: 3px;

              a {
                text-decoration: none;
                color: var(--white);
              }

              background-color: transparent;

              &.active {
                background-color: var(--primary);
                a {
                  color: var(--gray-background);
                }
              }
            }
          }
        }
      }
    }
  }
}

@mixin datatable-theme() {
  .datatable-header-cell {
    margin-bottom: 8px;
    padding: 10px 12px;
    .datatable-body-row {
      border: none;
    }
  }

  .datatable-body-row {
    padding: 8px 12px;
    margin-bottom: 8px;
    margin-block: 2px;
  }

  .datatable-body-cell {
    display: flex !important;
    align-items: center;
    overflow-y: hidden;
    height: fit-content;
  }

  label.datatable-checkbox {
    display: none;
  }

  .datatable-header-cell-template-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
  }

  .datatable-body-row.datatable-body-row.datatable-row-even,
  .datatable-body-row.datatable-body-row.datatable-row-odd{
    background-color: var(--row-background);
  }

  datatable-summary-row {
    .datatable-body-row {
      display: flex;
      align-items: center;
      background-color: var(--grey-300);
      font-weight: 700;
      color: black;
      margin: auto;
    }
  }
}

::ng-deep .datatable-summary-row.datatable-summary-row{
  display: flex;
  align-items: center;
  background-color: var(--grey-400);
  font-family: 'PoppinsSemiBold';
  font-weight: 700;
}

::ng-deep .datatable-header.datatable-header{
  font-family: 'PoppinsSemiBold';
  font-weight: var(--body4-font-weight);
  line-height: var(--body4-line-height);
  font-size: var(--body4-font-size);
}
