/* You can add global styles to this file, and also import other style files */
@use './app/shared/style/fonts.scss';
@use './app/shared/style/variables.scss';
@use './app/shared/style/typography.scss';
@use '@angular/material' as mat;
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import './app/shared/style/datatable';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~swiper/swiper-bundle.css';
@import '~swiper/swiper.min.css';

@include mat.core();
@include datatable-theme();

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;

  background-color: var(--body-background);
  color: var(--white);
}

@media (min-width: 769px) {
  html,
  body {
    background-image: url('/assets/png/light.png');
    background-size: cover;
  }
}
.modal {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cdk-overlay-backdrop.modal-background.cdk-overlay-backdrop-showing {
  background-color: var(--grey-900);
  opacity: 20%;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--grey-300);
  width: 2px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--grey-300);
}

html {
  scrollbar-width: 2px;
  scrollbar-color: var(grey-800) #f1f1f1;
}

.mat-bottom-sheet-container {
  box-shadow: none !important;
  background: none !important; 
  color: var(--white) !important;
}