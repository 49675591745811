:root {
  //colors
  --white: #ffffff;
  --black: #000000;
  --border: #dedede;
  --primary: #7013f8;
  --primary-hover: #6e51aa;
  --side-nav: #0e0e1e;
  --secondary: #f9b89a;
  --body-background: #111124;
  --negative: #eb625c;
  --negative-background: #ffebe6;
  --grey-100: #98a3ab40;
  --grey-200: #d1dae1;
  --grey-300: #696b7d;
  --grey-400: #526069;
  --grey-500: #37474f40;
  --grey-600: #181c2a;
  --grey-700: #d1d3e1;
  --grey-800: #98a3ab;
  --grey-900: #f6f6fa;
  --grey-1000: #1f2331;
  --grey-1100: #1b1b27;
  --grey-1200: #696b7d;
  --grey-1300: #676879;
  --grey-1400: #404248;
  --primary-background: #f3effa;
  --stepper-background: #e6e9ef;
  --primary-dark: #2b0a4d;

  --blue-100: #5dc6d2;
  --dark-blue: #232860;
  --positive: #13a77a;
  --gradient-1: linear-gradient(
    89.15deg,
    rgba(255, 191, 58, 0.8) -54.97%,
    rgba(71, 12, 127, 0.8) 85.08%,
    rgba(43, 10, 77, 0.8) 189.75%
  );
  --input: #98a3ab;
  --background-input: rgba(49, 51, 65, 1);
  --card-background: rgba(31, 35, 49, 1);
  --label: #d1dae1;
  --row-background: rgba(82, 96, 105, 0.25);

  //fonts
  --heading1-font-size: 36px;
  --heading1-line-height: 54px;
  --heading1-font-weight: 700;
  --heading1-spacing: -5%;

  --heading2-font-size: 32px;
  --heading2-line-height: 48px;
  --heading2-font-weight: 700;
  --heading2-spacing: -4%;

  --heading3-font-size: 24px;
  --heading3-line-height: 36px;
  --heading3-font-weight: 700;
  --heading3-spacing: -4%;

  --heading4-font-size: 20px;
  --heading4-line-height: 26px;
  --heading4-font-weight: 500;
  --heading4-spacing: -4%;

  --headline1-font-size: 20px;
  --headline1-line-height: 30px;
  --headline1-font-weight: 400;

  --headline2-font-size: 16px;
  --headline2-line-height: 30px;
  --headline2-font-weight: 400;

  --body1-font-size: 18px;
  --body1-line-height: 27px;
  --body1-font-weight: 400;

  --body2-font-size: 16px;
  --body2-line-height: 27px;
  --body2-font-weight: 500;

  --body3-font-size: 16px;
  --body3-line-height: 24px;
  --body3-font-weight: 500;

  --body4-font-size: 14px;
  --body4-line-height: 24px;
  --body4-font-weight: 400;

  --footnote1-font-size: 14px;
  --footnote1-line-height: 20px;
  --footnote1-font-weight: 400;

  --footnote2-font-size: 14px;
  --footnote2-line-height: 20px;
  --footnote2-font-weight: 500;

  --caption-font-size: 12px;
  --caption-line-height: 20px;
  --caption-font-weight: 400;

  --sticky-element-top: 56px;
}
