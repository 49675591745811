.typography-heading1 {
  font-family: 'PoppinsSemiBold';
  font-weight: var(--heading1-font-weight);
  line-height: var(--heading1-line-height);
  font-size: var(--heading1-font-size);
}

.typography-heading2 {
  font-family: 'PoppinsSemiBold';
  font-weight: var(--heading2-font-weight);
  line-height: var(--heading2-line-height);
  font-size: var(--heading2-font-size);
}

.typography-heading3-thin {
  font-family: 'PoppinsLight';
  font-weight: var(--heading3-font-weight);
  line-height: var(--heading3-line-height);
  font-size: var(--heading3-font-size);
}

.typography-heading3-bold {
  font-family: 'PoppinsSemiBold';
  font-weight: var(--heading3-font-weight);
  line-height: var(--heading3-line-height);
  font-size: var(--heading3-font-size);
}

.typography-heading4-bold {
  font-family: 'PoppinsSemiBold';
  font-weight: var(--heading4-font-weight);
  line-height: var(--heading4-line-height);
  font-size: var(--heading4-font-size);
}

.typography-heading4-thin {
  font-family: 'PoppinsLight';
  font-weight: var(--heading4-font-weight);
  line-height: var(--heading4-line-height);
  font-size: var(--heading4-font-size);
}

.typography-headline1 {
  font-family: 'PoppinsLight';
  font-weight: var(--headline1-font-weight);
  line-height: var(--headline1-line-height);
  font-size: var(--headline1-font-size);
}

.typography-headline2 {
  font-family: 'PoppinsLight';
  font-weight: var(--headline2-font-weight);
  line-height: var(--headline2-line-height);
  font-size: var(--headline2-font-size);
}

.typography-body1 {
  font-family: 'PoppinsLight';
  font-weight: var(--body1-font-weight);
  line-height: var(--body1-line-height);
  font-size: var(--body1-font-size);
}

.typography-body2-thin {
  font-family: 'PoppinsLight';
  font-weight: var(--body2-font-weight);
  line-height: var(--body2-line-height);
  font-size: var(--body2-font-size);
}

.typography-body2-bold {
  font-family: 'PoppinsSemiBold';
  font-weight: var(--body2-font-weight);
  line-height: var(--body2-line-height);
  font-size: var(--body2-font-size);
}

.typography-body3-bold {
  font-family: 'PoppinsSemiBold';
  font-weight: var(--body3-font-weight);
  line-height: var(--body3-line-height);
  font-size: var(--body3-font-size);
}

.typography-body3-thin {
  font-family: 'PoppinsLight';
  font-weight: var(--body3-font-weight);
  line-height: var(--body3-line-height);
  font-size: var(--body3-font-size);
}

.typography-body4-thin {
  font-family: 'PoppinsLight';
  font-weight: var(--body4-font-weight);
  line-height: var(--body4-line-height);
  font-size: var(--body4-font-size);
}

.typography-body4-bold {
  font-family: 'PoppinsSemiBold';
  font-weight: var(--body4-font-weight);
  line-height: var(--body4-line-height);
  font-size: var(--body4-font-size);
}

.typography-footnote1 {
  font-family: 'PoppinsLight';
  font-weight: var(--footnote1-font-weight);
  line-height: var(--footnote1-line-height);
  font-size: var(--footnote1-font-size);
}

.typography-footnote2-bold {
  font-family: 'PoppinsSemiBold';
  font-weight: var(--footnote2-font-weight);
  line-height: var(--footnote2-line-height);
  font-size: var(--footnote2-font-size);
}
.typography-footnote2-thin {
  font-family: 'PoppinsLight';
  font-weight: var(--footnote2-font-weight);
  line-height: var(--footnote2-line-height);
  font-size: var(--footnote2-font-size);
}

.typography-caption {
  font-family: 'PoppinsLight';
  font-weight: var(--caption-font-weight);
  line-height: var(--caption-line-height);
  font-size: var(--caption-font-size);
}

.color-primary {
  color: var(--primary);
}

.color-dark-blue {
  color: var(--dark-blue);
}

.color-light-gray {
  color: var(--label);
}

.color-gray-200 {
  color: var(--grey-200);
}

.color-white {
  color: var(--white);
}

.color-black {
  color: var(--black);
}
