@font-face {
  font-family: 'PoppinsExtraLight';
  src: url('../../../assets/fonts/Poppins-ExtraLight.ttf');
}

@font-face {
  font-family: 'PoppinsLight';
  src: url('../../../assets/fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: 'PoppinsBold';
  src: url('../../../assets/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'PoppinsSemiBold';
  src: url('../../../assets/fonts/Poppins-SemiBold.ttf');
}
